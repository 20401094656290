'use client';
import React from 'react';
import { useEnterPhonePinFormRu } from '../model/use-enter-phone-pin-ru';
import { AuthLayout, LayoutInner } from 'shared/ui/layout';
import Box from '@mui/material/Box';
import Header from 'widgets/header';
import Form from 'shared/ui/form';
import InputPhone from 'shared/ui/input-phone';
import { InputPinCode } from 'shared/ui/input-pin-code/input-pin-code';
import { useTranslation } from 'react-i18next';
import { AuthDialog } from 'entities/dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

export const EnterPhonePinFormRu: React.FC = () => {
  const { t } = useTranslation();
  const { formik, isSubmitDisabled, isLoading, error, setError, onForgotPasswordClick } =
    useEnterPhonePinFormRu();
  const isDesktop = useMediaQuery('(min-width:960px)');

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout>
        <Box>
          <Header
            title={t('sign-in.title')}
            onDemo={() => {
              window.location.replace(
                'https://www.figma.com/proto/Gq8kQCiU6lzvseVYf2unxY/WebMerchapp-userflow?node-id=7841-26547&t=zY5NsJI8v2qVFklM-0&scaling=scale-down-width&content-scaling=fixed&page-id=7841%3A8317&starting-point-node-id=7841%3A26547&disable-default-keyboard-nav=1&hide-ui=1'
              );
            }}
          />
          <Box
            pt={3}
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '500',
            }}
          >
            {t('sign-in.subtitle')}
          </Box>
          <LayoutInner>
            <Box
              pt={2}
              sx={{
                display: 'grid',
                gap: 2.5,
              }}
            >
              <InputPhone
                name="phone"
                mask={'+7 (999) 999 99 99'}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={formik.errors.phone}
              />
              <InputPinCode name="pin" value={formik.values.pin} onChange={formik.handleChange} />
              <AuthDialog.ServerError open={!!error} onOk={() => setError(null)}>
                {error}
              </AuthDialog.ServerError>
            </Box>
          </LayoutInner>
          <Box mt={4}>
            <Form.ForgotPasswordButton onClick={onForgotPasswordClick} />
          </Box>
        </Box>
        <Form.ProgressSubmit
          progress={0}
          theme={'primary'}
          disabled={isSubmitDisabled || isLoading}
        >
          {t('sign-in.submit')}
        </Form.ProgressSubmit>
        {isDesktop && <Form.SignUpButton />}
      </AuthLayout>
    </form>
  );
};
