import { useState, useEffect, startTransition } from 'react';

export function useDelayedOff(
  input?: boolean,
  options = {} as {
    defaultValue?: boolean;
    offDelay?: number;
  }
) {
  const { defaultValue = false, offDelay = 500 } = options;

  const [output, setOutput] = useState(defaultValue);

  useEffect(() => {
    if (input) {
      setOutput(true);
    } else {
      const timer = setTimeout(() => {
        startTransition(() => setOutput(false));
      }, offDelay);
      return () => clearTimeout(timer);
    }
  }, [input, offDelay]);

  return output;
}
